import Swal from "sweetalert2";
window.Swal = Swal;

let messageErrorDefault = "Não conseguimos processar o pedido, tente novamente mais tarde!";
let messageWarningDefault = "Ufaaa! Não apagamos nada.";
let timerDefault = 5000;
let messageSuccess = 'Gravado com sucesso!';

/**
 *
 * @param message {string}
 * @param type {string}
 * @param position {string}
 * @param timer {number}
 */
window.swallCustom = (message, type, position, timer = timerDefault) => {
    Swal.fire({
        position: position,
        icon: type,
        title: message,
        showConfirmButton: true,
        timer: timer
    })
};


window.toastInfo = (message = messageWarningDefault, position = "top-end", timer = timerDefault) => {
    const messageInfo = Swal.mixin({
        toast: true,
        position: position,
        showConfirmButton: true,
        timer: timer,
    });

    messageInfo.fire({
        icon: "info",
        title: message
    });
};

window.toastMessage = (message, type = "info", position = "top-end", timer = timerDefault) => {
    const swallMessage = Swal.mixin({
        toast: true,
        position: position,
        showConfirmButton: true,
        timer: timer,
    });

    swallMessage.fire({
        icon: type,
        title: message
    });
};

/**
 *
 * @param message {string}
 * @param timer {number}
 * @param options {object}
 */
window.toastSuccess = (message = messageSuccess, timer = timerDefault, options = {}) => {
    const success = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: timer,
        customClass:{
            actions: 'd-none',
        },
        ...options
    });

    success.fire({
        icon: 'success',
        title: message
    });
};

window.toastError = (message = messageErrorDefault, timer = timerDefault) => {
    const messageError = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: timer,
        customClass:{
            actions: 'd-none',
        }
    });

    messageError.fire({
        icon: 'error',
        title: message
    });
};

window.toastWarning = (message = messageWarningDefault, timer = timerDefault) => {
    const warning = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: timer,
        customClass:{
            actions: 'd-none',
        }
    });

    warning.fire({
        icon: 'warning',
        title: message
    });
};

window.alertErrorDatatable = (message = messageErrorDefault, timer = timerDefault) => {
    const errorDatatable = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: timer
    });

    errorDatatable.fire({
        icon: "error",
        title: message
    });
};

window.confirmAction = async (message = 'Tem a certeza?', subMessage = '') => {
    return Swal.fire({
        title: message,
        text: subMessage,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#1F97CC',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
    }).then((result) => {
        return result.value;
    })
}

window.confirmDelete = async (message = 'Tem a certeza?', subMessage = 'Esta ação é irreversível') => {
    return Swal.fire({
        title: message,
        text: subMessage,
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#1F97CC',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
    }).then((result) => {
        return result.value;
    })
}
